import React from "react";
import styles from "./teinMedia.module.css";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const TeinMedia = () => {
    const { translate } = useLanguage();
    
    return <div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.image}>
                <img alt="Tein" src={images.tm1} />
            </div>
            <div className={styles.text}>
                <div className={styles.title}>
                    <h1>{translate('tm1')}</h1>
                </div>
                <span>{translate('tm2')}</span>
            </div>
        </div>
        <div className={styles.content}>
            <div className={styles.image}>
                <img alt="Tein" src={images.tm2} />
            </div>
            <div className={styles.image}>
                <img alt="Tein" src={images.tm3} />
            </div>
        </div>
    </div>;
}

export default TeinMedia;