import React from "react";
import styles from "./ourEvents.module.css";
import video2 from "../../assets/videos/TeinMulakat.mp4"
import ImageSlider from "../../components/slider";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const slides = [
  { url: images.event1 },
  { url: images.event8 },
  { url: images.event9 },
  { url: images.event6 },
  { url: images.event7 },
  { url: images.event2 },
  { url: images.event3 },
  { url: images.event4 },
  { url: images.event5 },
]

const OurEvents = () => {
  const { translate } = useLanguage();
  
  return <div className={styles.container}>
    <div className={styles.title}>
      <h1>{translate('e1')}</h1>
    </div>
    <div>
      <ImageSlider slides={slides} />
    </div>
    <div className={styles.video}>
      <h2>{translate('e2')}</h2>
      <video width="800" controls>
        <source src={video2} type="video/mp4" />
      </video>
    </div>
  </div>;
}

export default OurEvents;