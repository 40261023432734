import React from "react";
import styles from "./vrAcademic.module.css";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const VrAcademic = () => {
  const { translate } = useLanguage();

  return <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.image}>
        <img alt="Tein" src={images.vr} />
      </div>
      <div className={styles.text}>
        <h1>{translate('t1')}</h1>
        <span>{translate('t2')}</span>
        <div className={styles.image}>
          <img alt="Tein" src={images.vracademic} />
        </div>
      </div>
    </div>
    <div className={styles.video}>
      <iframe
        width="1250"
        height="600"
        src="https://www.youtube.com/embed/c4tq_T6O_Z4?rel=0"
        title="Tein Yazılım şirketi olarak geliştirdiğimiz VR Academic 4D dil öğrenme oyunumuzun lansmanının videosu"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen>
      </iframe>
    </div>
  </div>
}

export default VrAcademic;