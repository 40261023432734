
import { images } from "../../assets";
import ImageSlider from "../../components/slider";
import { useLanguage } from "../../languageContext";
import styles from "./teinAcademy.module.css";

const TeinAcademy = () => {
    const { translate } = useLanguage();

    const slides = [
        { url: images.ta1 },
        { url: images.ta2 },
        { url: images.ta3 },
        { url: images.ta4 },
    ]

    return <div className={styles.container}>
        <div className={styles.title}>
            <h1>{translate('k1')}</h1>
        </div>
        <div>
            <ImageSlider slides={slides} />
        </div>
        <div className={styles.content}>
            <div className={styles.image}>
                <img src={images.office4} alt="Tein" />
            </div>
            <div className={styles.text}>
                <h2>{translate('k2')}</h2>
                <span>{translate('k3')}</span>
            </div>
        </div>
        <div className={styles.content}>
            <div className={styles.image}>
                <img src={images.office5} alt="Tein" />
            </div>
            <div className={styles.text}>
                <h2>{translate('k4')}</h2>
                <span>{translate('k5')}</span>
            </div>
        </div>
    </div>
}

export default TeinAcademy;