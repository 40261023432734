import React from "react";
import styles from "./abouts.module.css";
import url from "../../assets/url";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const AboutUs = () => {
  const { translate } = useLanguage();

  return <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.image}>
        <img src={images.office1} alt="Tein" />
      </div>
      <div className={styles.text}>
        <h1>{translate('u1')}</h1>
        <p>{translate('u2')}</p>
      </div>
    </div>
    <div className={styles.counter}>
      <div className={styles.count}>
        <span>46+</span>
        <h4>{translate('u3')}</h4>
      </div>
      <div className={styles.count}>
        <span>215+</span>
        <h4>{translate('u4')}</h4>
      </div>
      <div className={styles.count}>
        <span>7+</span>
        <h4>{translate('u5')}</h4>
      </div>
      <div className={styles.count}>
        <span>130+</span>
        <h4>{translate('u6')}</h4>
      </div>
    </div>
  </div>;
}

export default AboutUs;