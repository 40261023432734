import React from "react";
import HeaderMenuBar from "../../../components/headerMenuBar";
import Footer from "../../../components/footer";
import styles from "./mainLayout.module.css";
import ScrollToTop from "./scrool";

const MainLayout = ({ Container }: {Container: any} ) => {
  return (
    <div className={styles.container}>
      <ScrollToTop />
      <HeaderMenuBar/>
      <Container />
      <Footer />
    </div>
  );
}

export default MainLayout;