import React from "react";
import styles from "./expertArea.module.css";
import { svg } from "../../assets";
import { useLanguage } from "../../languageContext";

const ExpertArea = () => {
  const { translate } = useLanguage();
  
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h3>{translate('a1')}</h3>
        <p>{translate('a2')}</p>
      </div>
      <div className={styles.body}>
        <div className={styles.cards}>
          <div className={styles.card}>
            <img src={svg.dollar} alt="Tein" />
            <p>{translate('a3')}</p>
            <span>{translate('a4')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.mobile} alt="Tein" />
            <p>{translate('a5')}</p>
            <span>{translate('a6')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.bank} alt="Tein" />
            <p>{translate('a7')}</p>
            <span>{translate('a8')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.customer} alt="Tein" />
            <p>{translate('a9')}</p>
            <span>{translate('a10')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.sales} alt="Tein" />
            <p>{translate('a11')}</p>
            <span>{translate('a12')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.quality} alt="Tein" />
            <p>{translate('a13')}</p>
            <span>{translate('a14')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.data} alt="Tein" />
            <p>{translate('a15')}</p>
            <span>{translate('a16')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.archive} alt="Tein" />
            <p>{translate('a17')}</p>
            <span>{translate('a18')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.agile} alt="Tein" />
            <p>{translate('a19')}</p>
            <span>{translate('a20')}</span>
          </div>
          <div className={styles.card}>
            <img src={svg.management} alt="Tein" />
            <p>{translate('a21')}</p>
            <span>{translate('a22')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpertArea;
