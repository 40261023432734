import React, { createContext, useContext, useState, ReactNode } from 'react';
import translations from './assets/translations';

interface LanguageContextType {
  changeLanguage: (lng: string) => void;
  translate: (key: string) => string;
}

const defaultLanguageContext: LanguageContextType = {
  changeLanguage: () => {},
  translate: (key: string) => key
};

const LanguageContext = createContext<LanguageContextType>(defaultLanguageContext);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState('tr');

  const changeLanguage = (lng: string) => {
    setLanguage(lng);
  };

  const translate = (key: string) => {
    return translations[language][key] || key;
  };

  return (
    <LanguageContext.Provider value={{ changeLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

