import React, { useEffect, useState } from 'react';
import styles from './slider.module.css';

interface Slide {
  url: string;
}

interface ImageSliderProps {
  slides: Slide[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const isLastSlide = currentIndex === slides.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }, 5000);
    return () => clearInterval(timer);
  }, [currentIndex, slides.length]);
  

  return (
    <div className={styles.slider}>
      <div>
        <div onClick={goToPrevious} className={styles.leftArrow}>
          ❰
        </div>
        <div onClick={goToNext} className={styles.rightArrow}>
          ❱
        </div>
      </div>
      <div
        className={styles.slide}
      >
        <img src={slides[currentIndex].url} alt="Tein" />
      </div>
      <div className={styles.dotsContainer}>
        {slides.map((slide, slideIndex) => (
          <div
            className={styles.dot}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
