import React from "react";
import styles from "./socialMedia.module.css";
import video1 from "../../assets/videos/TeinHaber.mp4"
import ImageSlider from "../../components/slider";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const slides = [
  { url: images.media1 },
  { url: images.media2 },
  { url: images.media3 }
]

const SocialMedia = () => {
  const { translate } = useLanguage();
  
  return <div className={styles.container}>
    <div className={styles.title}>
      <h1>{translate('o1')}</h1>
    </div>
    <div>
      <ImageSlider slides={slides} />
    </div>
    <div className={styles.video}>
      <video width="1000" controls>
        <source src={video1} type="video/mp4" />
      </video>
    </div>
  </div>;
}

export default SocialMedia;