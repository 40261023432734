import React from "react";
import styles from "./ourProjects.module.css";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const OurProjects = () => {
    const { translate } = useLanguage();

    return <div className={styles.container}>
        <div className={styles.title}>
            <h1>{translate('p1')}</h1>
        </div>
        <div className={styles.content}>
            <div className={styles.image}>
                <img alt="Tein" src={images.vracademic} />
            </div>
            <div className={styles.text}>
                <h2>{translate('p2')}</h2>
                <span>{translate('p3')}</span>
            </div>
        </div>
        <div className={styles.content}>
            <div className={styles.image}>
                <img alt="Tein" src={images.overcatch} />
            </div>
            <div className={styles.text}>
                <h2>{translate('p4')}</h2>
                <span>{translate('p5')}</span>
            </div>
        </div>
        <div className={styles.content}>
            <div className={styles.image}>
                <img alt="Tein" src={images.uetds} />
            </div>
            <div className={styles.text}>
                <h2>{translate('p6')}</h2>
                <span>{translate('p7')}</span>
            </div>
        </div>

    </div>;
}

export default OurProjects;