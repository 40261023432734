import React from "react";
import styles from "./certificateReference.module.css";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const CertificateReference = () => {
  const { translate } = useLanguage();
  
  return <div className={styles.container}>
    <div>
      <div className={styles.title}>
        <h1>{translate('c1')}</h1>
      </div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <img src={images.certificate1} alt="Tein" />
        </div>
        <div className={styles.card}>
          <img src={images.certificate2} alt="Tein" />
        </div>
        <div className={styles.card}>
          <img src={images.certificate3} alt="Tein" />
        </div>
        <div className={styles.card}>
          <img src={images.certificate4} alt="Tein" />
        </div>
      </div>
    </div>
    {/* <div>
      <div className={styles.title}>
        <h1>{translate('c2')}</h1>
      </div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <img src={images.certificate1} alt="Tein" />
        </div>
        <div className={styles.card}>
          <img src={images.certificate2} alt="Tein" />
        </div>
        <div className={styles.card}>
          <img src={images.certificate3} alt="Tein" />
        </div>
        <div className={styles.card}>
          <img src={images.certificate4} alt="Tein" />
        </div>
      </div>
    </div> */}
  </div>;
}

export default CertificateReference;