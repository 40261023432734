// image imports
import logo from './images/logo.png'
import logo2 from './images/logo2.png'
import logo3 from './images/logo3.png'
import vr from './images/VR-glasess.png'
import vracademic from './images/vracademic.png'
import overcatch from './images/OverCatch.png'
import uetds from './images/UETDS.png'
import event1 from './images/event1.jpg'
import event2 from './images/event2.jpg'
import event3 from './images/event3.jpg'
import event4 from './images/event4.jpg'
import event5 from './images/event5.jpg'
import event6 from './images/event6.jpg'
import event7 from './images/event7.jpeg'
import event8 from './images/event8.jpeg'
import event9 from './images/event9.jpeg'
import mng1 from './images/mng1.jpg'
import mng2 from './images/mng2.jpg'
import mng3 from './images/mng3.jpg'
import mng4 from './images/mng4.jpg'
import mng5 from './images/mng5.jpeg'
import mng6 from './images/mng6.jpg'
import office1 from './images/office1.jpeg'
import office2 from './images/office2.jpeg'
import office3 from './images/office3.jpeg'
import office4 from './images/office4.jpeg'
import office5 from './images/office5.jpeg'
import office6 from './images/office6.jpeg'
import media1 from './images/media1.jpg'
import media2 from './images/media2.png'
import media3 from './images/media3.jpg'
import tm1 from './images/tm1.png'
import tm2 from './images/tm2.jpeg'
import tm3 from './images/tm3.jpeg'
import ta1 from './images/ta1.jpeg'
import ta2 from './images/ta2.jpeg'
import ta3 from './images/ta3.jpeg'
import ta4 from './images/ta4.jpeg'
import certificate1 from './images/ISO 9001-1.jpg'
import certificate2 from './images/ISO 20000-1-1.jpg'
import certificate3 from './images/ISO 22301-1.jpg'
import certificate4 from './images/ISO 27001-1.jpg'
import bl1 from './images/bl1.jpg'
import bl2 from './images/bl2.jpeg'
import bl3 from './images/bl3.jpeg'
import bl4 from './images/bl4.jpeg'
import bl5 from './images/bl5.jpeg'


// svg imports
import menu from './svg/menu-svgrepo-com.svg'
import angledown from './svg/angle-down.svg'
import marker from './svg/marker.svg'
import phone from './svg/phone-call.svg'
import mail from './svg/envelope.svg'
import light from './svg/8664906_lightbulb_bulb_icon.svg'
import dollar from './svg/dollar1-svgrepo-com.svg'
import mobile from './svg/iphone-smart-phone-svgrepo-com.svg'
import bank from './svg/bank-svgrepo-com.svg'
import customer from './svg/customer-service-agent-svgrepo-com.svg'
import sales from './svg/sales-up-graph-svgrepo-com.svg'
import quality from './svg/data-quality-svgrepo-com.svg'
import data from './svg/data-center-database-hosting-svgrepo-com.svg'
import archive from './svg/archive-svgrepo-com.svg'
import agile from './svg/agile-dev-developer-svgrepo-com.svg'
import management from './svg/user-group-svgrepo-com.svg'
import tr from './svg/flag-tr-svgrepo-com.svg'
import en from './svg/flag-gb-svgrepo-com.svg'
import de from './svg/flag-de-svgrepo-com.svg'
import ru from './svg/flag-ru-svgrepo-com.svg'
import sa from './svg/flag-sa-svgrepo-com.svg'
import insta from './svg/instagram-1-svgrepo-com.svg'
import yt from './svg/youtube-color-svgrepo-com.svg'
import linkd from './svg/linkedin-svgrepo-com.svg'


export const images = {
    logo, logo2, logo3, vr, vracademic, overcatch, uetds,
    event1, event2, event3, event4, event5, event6, 
    event7, event8, event9,  
    mng1, mng2, mng3, mng4, mng5, mng6,
    office1, office2, office3, office4, office5, office6,
    bl1, bl2, bl3, bl4, bl5,
    certificate1, certificate2, certificate3, certificate4,
    media1, media2, media3,
    tm1, tm2, tm3, ta1, ta2, ta3, ta4
};

export const svg = {
    menu, angledown, marker, phone, mail, light,
    dollar, mobile, bank, customer, sales, quality, data, archive, agile, management,
    tr, en, de, ru, sa, insta, yt, linkd
}
