import React from "react";
import styles from "./workZone.module.css";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const WorkZone = () => {
  const { translate } = useLanguage();
  
  return <div className={styles.container}>
    <div className={styles.title}>
      <h1>{translate('w1')}</h1>
    </div>
    <div>
      <div className={styles.content}>
        <div className={styles.image}>
          <img alt="Tein" src={images.office1} />
        </div>
        <div className={styles.text}>
          <h2>{translate('w2')}</h2>
          <span>{translate('w3')}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.image}>
          <img alt="Tein" src={images.office2} />
        </div>
        <div className={styles.text}>
          <h2>{translate('w4')}</h2>
          <span>{translate('w5')}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.image}>
          <img alt="Tein" src={images.office3} />
        </div>
        <div className={styles.text}>
          <h2>{translate('w6')}</h2>
          <span>{translate('w7')}</span>
        </div>
      </div>
    </div>
  </div>
};

export default WorkZone;