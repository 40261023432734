import React, { useEffect, useState } from "react";
import styles from "./headerMenuBar.module.css";
import { images, svg } from "../../assets";
import { Link, useLocation } from "react-router-dom";
import DropDownMenu from "../dropDownMenu";
import { useLanguage } from "../../languageContext";

const HeaderMenuBar = () => {
  const languages = [
    { code: 'tr', name: 'Türkçe', icon: svg.tr },
    { code: 'en', name: 'English', icon: svg.en },
    { code: 'de', name: 'German', icon: svg.de },
    { code: 'ru', name: 'Russian', icon: svg.ru },
    { code: 'sa', name: 'Arabic', icon: svg.sa }
  ]
  const [menuStatus, setMenuStatus] = useState(false);
  const [navbar, setNavbar] = useState(false)
  const { translate, changeLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages.find(lang => lang.code === 'tr') || languages[0]);

  const corparationData = [
    { title: translate('h3'), link: '/managementstaff' },
    { title: translate('h4'), link: '/expertarea' },
    { title: translate('h5'), link: '/certificate' }
  ]
  const workZoneData = [
    { title: translate('h7'), link: '/workzone' },
    { title: translate('h8'), link: '/ourprojects' },
    { title: translate('h9'), link: '/teinacademy' },
    { title: translate('h10'), link: '/businesslounge' },
    { title: translate('h11'), link: '/vracademic' }
  ]
  const aboutData = [
    { title: translate('h13'), link: '/about-us' },
    { title: translate('h14'), link: '/ourevents' },
    { title: translate('h15'), link: '/socialmedia' },
    { title: translate('h16'), link: '/teinmedia' }
  ]

  const location = useLocation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language: { code: string; name: string; icon: string }) => {
    changeLanguage(language.code);
    setSelectedLanguage(language);
    setIsOpen(false);
  };


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeBackground = () => {
    if (location.pathname === '/') {
      setNavbar(true)
      document.body.style.overflow = 'hidden'
    } else {
      setNavbar(false)
      document.body.style.overflow = 'auto'
    }
  }

  useEffect(() => {
    changeBackground();
  }, [changeBackground])

  return (
    <div className={navbar ? styles.container : styles.containerActive}>
      <div className={styles.logoLabel}>
        <Link to="/">
          <img alt="tein" src={images.logo} />
        </Link>
      </div>
      <div onClick={() => setMenuStatus((prev) => !prev)} className={styles.mobileMenuIcon}>
        <img alt="tein" src={svg.menu} />
      </div>
      <div className={styles.menuLinks}>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <Link to="/">
            <span>{translate('h1')}</span>
          </Link>
        </div>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <DropDownMenu label={translate('h2')} menuList={corparationData} />
        </div>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <DropDownMenu label={translate('h6')} menuList={workZoneData} />
        </div>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <DropDownMenu label={translate('h12')} menuList={aboutData} />
        </div>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <Link to="/contact">
            <span>{translate('h17')}</span>
          </Link>
        </div>
        <div className={styles.dropdown}>
          <button onClick={toggleDropdown} className={styles.dropdownToggle}>
            <img src={selectedLanguage.icon} alt={selectedLanguage.name} />
          </button>
          {isOpen && (
            <ul className={styles.dropdownMenu}>
              {languages.map(language => (
                <li key={language.code} onClick={() => handleLanguageChange(language)}>
                  <img src={language.icon} alt={language.name} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {menuStatus &&
        <div className={styles.modal} onClick={() => setMenuStatus((prev) => !prev)}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <div>
              <div className={styles.modalLink}>
                <Link to="/" onClick={() => setMenuStatus(false)}>
                  »{translate('h1')}
                </Link>
              </div>
              <div className={styles.modalLinks}>
                <div>
                  <span>»{translate('h2')}</span>
                </div>
                <div>
                  <Link to="/managementstaff" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h3')}
                  </Link>
                </div>
                <div>
                  <Link to="/expertarea" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h4')}
                  </Link>
                </div>
                <div>
                  <Link to="/certificate" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h5')}
                  </Link>
                </div>
              </div>
              <div className={styles.modalLinks}>
                <div>
                  <span>»{translate('h6')}</span>
                </div>
                <div>
                  <Link to="/workzone" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h7')}
                  </Link>
                </div>
                <div>
                  <Link to="/ourprojects" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h8')}
                  </Link>
                </div>
                <div>
                  <Link to="/teinacademy" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h9')}
                  </Link>
                </div>
                <div>
                  <Link to="/businesslounge" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h10')}
                  </Link>
                </div>
                <div>
                  <Link to="/vracademic" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h11')}
                  </Link>
                </div>
              </div>
              <div className={styles.modalLinks}>
                <div>
                  <span>»{translate('h12')}</span>
                </div>
                <div>
                  <Link to="/about-us" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h13')}
                  </Link>
                </div>
                <div>
                  <Link to="/ourevents" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h14')}
                  </Link>
                </div>
                <div>
                  <Link to="/socialmedia" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h15')}
                  </Link>
                </div>
                <div>
                  <Link to="/teinmedia" onClick={() => setMenuStatus(false)}>
                    ◦{translate('h16')}
                  </Link>
                </div>
              </div>
              <div className={styles.modalLink}>
                <Link to="/contact" onClick={() => setMenuStatus(false)}>
                  »{translate('h17')}
                </Link>
              </div>
            </div>
            <div className={styles.dropdown}>
              <button onClick={toggleDropdown} className={styles.dropdownToggle}>
                <img src={selectedLanguage.icon} alt={selectedLanguage.name} />
              </button>
              {isOpen && (
                <ul className={styles.dropdownMenu}>
                  {languages.map(language => (
                    <li key={language.code} onClick={() => handleLanguageChange(language)}>
                      <img src={language.icon} alt={language.name} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      }
    </div>

  );
}

export default HeaderMenuBar;