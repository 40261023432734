const url = {
    ISTANBUL_MAP: "https://www.google.com/maps/search/Atak%C3%B6y+7-8-9-10.+K%C4%B1s%C4%B1m+Mahallesi+%C3%87oban%C3%A7e%C5%9Fme+E-5+Caddesi+,+No:12-2+Nivo+Atak%C3%B6y+B%2Fblok+7.+Kat.+Daire:+59+Bak%C4%B1rk%C3%B6y/@41.0277005,28.7089977,12z/data=!3m1!4b1?entry=ttu",
    DUZCE_MAP: "https://www.google.com/maps/place/D%C3%BCzce+Teknopark/@40.9014143,31.1700924,17z/data=!3m1!4b1!4m5!3m4!1s0x409da13f268b673d:0x82ffe8c18019050!8m2!3d40.9013597!4d31.172298",
    BOLU_MAP: "https://www.google.com.tr/maps/search/K%C3%BClt%C3%BCr+Mah.+%C3%87akmaklar+Cad.+Bolu+Teknokent+No:2%2F1/@40.737899,31.6016779,18z?hl=tr&entry=ttu",
    TEL: "tel:0850 303 46 38",
    MAIL: "mailto:info@tein.com.tr",
    INSTA: "https://www.instagram.com/teinteknoloji",
    YOUTUBE: "https://www.youtube.com/@teintechnologya.s.6535",
    LINKD: "https://tr.linkedin.com/company/solutions44",

}

export default url