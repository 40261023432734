import React from 'react'
import styles from './dropDownMenu.module.css';
import { Link } from 'react-router-dom';
import { svg } from '../../assets';

interface DropDownMenuProps {
  label: string,
  menuList: {title?:string, link: string}[], 
}

const DropDownMenu = ({ label, menuList }: DropDownMenuProps) => {
  return (
    <div>
      <div className={styles.menuLink}>
        <span>{label}</span>
        <img alt="Tein" src={svg.angledown} />
        <div className={styles.dropMenu}>
          {menuList.map((item, index) => (
            <div>
              <Link to = {item.link}>
                <p key={index}>{item.title}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DropDownMenu                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
