import React from "react";
import styles from "./businessLounge.module.css";
import { images } from "../../assets";
import { useLanguage } from "../../languageContext";

const BusinessLounge = () => {
  const { translate } = useLanguage();

  return <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.image}>
        <img alt="Tein" src={images.bl1} />
      </div>
      <div className={styles.text}>
        <div className={styles.title}>
          <h1>{translate('b1')}</h1>
        </div>
        <span>{translate('b2')}</span>
      </div>
    </div>
    <div className={styles.content}>
      <div className={styles.image}>
        <img alt="Tein" src={images.bl2} />
      </div>
      <div className={styles.image}>
        <img alt="Tein" src={images.bl3} />
      </div>
    </div>
    <div className={styles.content}>
      <div className={styles.image}>
        <img alt="Tein" src={images.bl4} />
      </div>
      <div className={styles.image}>
        <img alt="Tein" src={images.bl5} />
      </div>
    </div>
  </div>;
}

export default BusinessLounge;