import React from "react";
import styles from "./footer.module.css";
import { images, svg } from "../../assets";
import { Link, useLocation } from "react-router-dom";
import url from "../../assets/url";
import { useLanguage } from "../../languageContext";

const Footer = () => {

  const { pathname } = useLocation();
  const { translate } = useLanguage();

  const isHomePage = pathname === "/";

  if (isHomePage) {
    return null;
  }


  return (
    <div className={styles.container}>
      <div className={styles.footerLogo}>
        <div>
          <img alt="Tein" src={images.logo} />
        </div>
        <div className={styles.footerLogos}>
          <img alt="Tein" src={images.logo2} />
          <img alt="Tein" src={images.logo3} />
        </div>
      </div>
      <div className={styles.footerLinks}>
        <div>
          <h2>{translate('f1')}</h2>
        </div>
        <div>
          <div className={styles.menuLink}>
            <Link to="/">
              <span>{translate('h1')}</span>
            </Link>
          </div>
          <div className={styles.menuLink}>
            <Link to="/managementstaff">
              <span>{translate('h2')}</span>
            </Link>

          </div>
          <div className={styles.menuLink}>
            <Link to="/workzone">
              <span>{translate('h6')}</span>
            </Link>
          </div>
          <div className={styles.menuLink}>
            <Link to="/about-us">
              <span>{translate('h12')}</span>
            </Link>
          </div>
          <div className={styles.menuLink}>
            <Link to="/contact">
              <span>{translate('h17')}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.office}>
        <div>
          <h2>{translate('f2')}</h2>
        </div>
        <div>
          <div className={styles.officeTitle}>
            <div>
              <img alt="Tein" src={svg.marker} />
              <span>Tein İstanbul</span>
            </div>
            <div>
              <a href={url.ISTANBUL_MAP} target="blank">Ataköy 7-8-9-10. Kısım Mahallesi Çobançeşme E-5 Caddesi İstanbul/Bakırköy</a>
            </div>
          </div>
          <div className={styles.officeTitle}>
            <div>
              <img alt="Tein" src={svg.marker} />
              <span>Tein Düzce</span>
            </div>
            <div>
              <a href={url.DUZCE_MAP} target="blank"> Orhangazi Mahallesi Teknopak Caddesi Teknopark Apt. Düzce/Merkez</a>
            </div>
          </div>
          <div className={styles.officeTitle}>
            <div>
              <img alt="Tein" src={svg.marker} />
              <span>Tein Bolu</span>
            </div>
            <div>
              <a href={url.BOLU_MAP} target="blank">Kültür Mah. Çakmaklar Cad. Bolu Teknokent No:2/1</a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contact}>
        <div>
          <h2>{translate('f3')}</h2>
        </div>
        <div className={styles.contactPhone}>
          {/* <div>
            <img alt="Tein" src={svg.phone} />
            <a href={url.TEL}>0850 303 46 38</a>
          </div> */}
          <div>
            <img alt="Tein" src={svg.phone} />
            <a href={url.TEL}>0850 303 46 38</a>
          </div>
        </div>
        <div className={styles.contactMail}>
          <img alt="Tein" src={svg.mail} />
          <a href={url.MAIL}>info@tein.com.tr</a>
        </div>
        <div className={styles.contactMail}>
          <img alt="Tein" src={svg.mail} />
          <a href={url.MAIL}>ik@tein.com.tr</a>
        </div>
        <div className={styles.footerMedia}>
          <div>
            <a href={url.INSTA} target="blank"><img alt="Tein" src={svg.insta} /></a>
          </div>
          <div>
            <a href={url.YOUTUBE} target="blank"><img alt="Tein" src={svg.yt} /></a>
          </div>
          <div>
            <a href={url.LINKD} target="blank"><img alt="Tein" src={svg.linkd} /></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;