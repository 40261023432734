import { createBrowserRouter } from "react-router-dom";
import MainLayout from "./layouts/mainLayout";
import AboutUs from "../pages/aboutUs";
import Contact from "../pages/contact";
import HomePage from "../pages/homePage";
import WorkZone from "../pages/workZone";
import VrAcademic from "../pages/vrAcademic";
import ManagementStaff from "../pages/managementStaff";
import ExpertArea from "../pages/expertArea";
import CertificateReference from "../pages/certificateReference";
import OurProjects from "../pages/ourProjects";
import TeinAcademy from "../pages/teinAcademy";
import SocialMedia from "../pages/socialMedia";
import BusinessLounge from "../pages/businessLounge";
import TeinMedia from "../pages/teinMedia";
import OurEvents from "../pages/ourEvents";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout Container={HomePage} />,
  },
  {
    path: "/managementstaff",
    element: <MainLayout Container={ManagementStaff} />,
  },
  {
    path: "/expertarea",
    element: <MainLayout Container={ExpertArea} />,
  },
  {
    path: "/certificate",
    element: <MainLayout Container={CertificateReference} />,
  },
  {
    path: "/workzone",
    element: <MainLayout Container={WorkZone} />,
  },
  {
    path: "/ourprojects",
    element: <MainLayout Container={OurProjects} />,
  },
  {
    path: "/teinacademy",
    element: <MainLayout Container={TeinAcademy} />,
  },
  {
    path: "/businesslounge",
    element: <MainLayout Container={BusinessLounge} />,
  },
  {
    path: "/vracademic",
    element: <MainLayout Container={VrAcademic} />,
  },
  {
    path: "/about-us",
    element: <MainLayout Container={AboutUs} />,
  },
  {
    path: "/ourevents",
    element: <MainLayout Container={OurEvents} />,
  },
  {
    path: "/socialmedia",
    element: <MainLayout Container={SocialMedia} />,
  },
  {
    path: "/teinmedia",
    element: <MainLayout Container={TeinMedia} />,
  },
  {
    path: "/contact",
    element: <MainLayout Container={Contact} />,
  },
]);

export default router;