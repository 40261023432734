import React, { FormEvent, useEffect, useRef, useState } from "react";
import styles from "./contact.module.css";
import Swal from "sweetalert2";
import { useLanguage } from "../../languageContext";

const Contact = () => {
  const [kvkk, setKvkk] = useState(false);
  const [light, setLight] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { translate } = useLanguage();

  const openKvkk = () => {
    setKvkk(true);
  };

  const closeKvkk = () => {
    setKvkk(false);
  };

  const openLight = () => {
    setLight(true);
  };

  const closeLight = () => {
    setLight(false);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    formData.append("access_key", "f323a4bb-36c1-45ce-a066-26e39b4d1f8a");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: translate('i9'),
        text: translate('i10'),
        icon: "success"
      });
      if (formRef.current) {
        formRef.current.reset();
      }
    }
  };

  useEffect(() => {
    if (kvkk || light) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [kvkk, light]);


  return <div className={styles.container}>
    <div className={styles.title}>
      <h1>{translate('i1')}</h1>
    </div>
    <section className={styles.contact}>
      <form ref={formRef} onSubmit={onSubmit}>
        <div className={styles.inputBox}>
          <input name="name" type="text" className={styles.field} placeholder={translate('i2')} required />
        </div>
        <div className={styles.inputBox}>
          <input name="email" type="email" className={styles.field} placeholder={translate('i3')} required />
        </div>
        <div className={styles.inputBox}>
          <input name="phone" type="tel" className={styles.field} placeholder="0 (XXX) XXX XX XX" required />
        </div>
        <div className={styles.inputBox}>
          <textarea name="message" className={styles.fieldMess} placeholder={translate('i4')} required></textarea>
        </div>
        <div className={styles.checkbox}>
          <input type="checkbox" required />
          <label><span onClick={openKvkk}>{translate('i6')}</span>{translate('i5')}</label>
        </div>
        <div className={styles.checkbox}>
          <input type="checkbox" required />
          <label><span onClick={openLight}>{translate('i7')}</span>{translate('i5')}</label>
        </div>
        <div>
          <button type="submit">{translate('i8')}</button>
        </div>
      </form>
    </section>
    {kvkk &&
      <div className={styles.modal} onClick={closeKvkk}>
        <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <span className={styles.closeButton} onClick={closeKvkk}>&times;</span>
          <div>
            <h1>{translate('kv1')}</h1>
            <h2>{translate('kv2')}</h2>
            <h3>{translate('kv3')}</h3>
            <p>{translate('kv4')}</p>
            <p>{translate('kv5')}</p>
            <h3>{translate('kv6')}</h3>
            <p>{translate('kv7')}</p>
            <p>{translate('kv8')}</p>
            <p>{translate('kv9')}</p>
            <h3>{translate('kv10')}</h3>
            <p>{translate('kv11')}</p>
            <p>{translate('kv12')}</p>
          </div>
        </div>
      </div>
    }
    {light &&
      <div className={styles.modal} onClick={closeLight}>
        <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <span className={styles.closeButton} onClick={closeLight}>&times;</span>
          <h1>{translate('lh1')}</h1>
          <p>{translate('lh2')}</p>
          <p><strong>{translate('lh3')}</strong>
            <br />
            {translate('lh4')}</p>
          <p><strong>{translate('lh5')}</strong><br />
            {translate('lh6')}<br />
            {translate('lh7')}<br />
            {translate('lh8')}<br />
            {translate('lh9')}</p>
          <p><strong>{translate('lh10')}</strong><br />
            {translate('lh11')}<br />
            {translate('lh12')}<br />
            {translate('lh13')}<br />
            {translate('lh14')}<br />
            {translate('lh15')}<br />
            {translate('lh16')}</p>
          <p><strong>{translate('lh17')}</strong><br />
            {translate('lh18')}</p>
          <p><strong>{translate('lh19')}</strong><br />
            {translate('lh20')}<br />
            {translate('lh21')}</p>
          <p><strong>{translate('lh22')}</strong><br />
            {translate('lh23')}</p>
        </div>
      </div>
    }
  </div>
}

export default Contact;